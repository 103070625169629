import React, { useState, useEffect } from 'react'
import { navigate, Link } from 'gatsby'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import get from 'lodash/get'
import qs from 'qs'

import SEO from '../../components/SEO'
import { Loader } from '../../_shared/components'

export default function Page(props) {
  const params = (
    typeof window !== 'undefined'
      ? qs.parse(window.location.search, { ignoreQueryPrefix: true })
      : {}
  )

  const orderId = Number(params.orderId) || 0

  const { refetch: fetchInvoiceStatus } = useQuery(
    gql`
      query CheckInvoiceStatus($orderId: Int!) {
        invoiceStatus(invoiceId: $orderId)
      }
    `,
    { variables: { orderId }, fetchPolicy: 'network-only' },
  )

  useEffect(() => {
    let timeout
    const waitStart = Date.now()
    const pollWait = 2000 // ms

    async function refresh() {
      // Something's not right. orderId must be set in order to check
      if (!orderId) {
        console.log('Missing orderId in vipps return url')
        window.location.pathname = '/gavekort/feil'
        return
      }

      // Stop checking after 30s and send user to timeout screen
      if (Date.now() - waitStart > 30000) {
        window.location.pathname = '/gavekort/feil'
        return
      }

      // Fetch updated data
      const res = await fetchInvoiceStatus()

      // Check if invoice has been paid
      if (get(res, 'data.invoiceStatus') === 'paid') {
        window.location.pathname = '/gavekort/takk'
        return
      }

      // No plan found, try again in pollWait ms
      timeout = setTimeout(refresh, pollWait)
    }

    refresh()
    timeout = setTimeout(refresh, pollWait) // wait for pollWait ms before retrying

    return () => clearTimeout(timeout)
  }, [fetchInvoiceStatus, orderId])

  return (
    <>
      <SEO title="Gavekort" description="Kjøp gavekort" />
      <Loader text="Venter på bekreftelse fra vipps" delay={0} type="filled" />
    </>
  )
}
